import styled from "styled-components";

const Div = styled.div`
    text-align: center;
    font-size: 50px;

    top: 30px;
`;

export function Home(){
    return ( 
        <Div>Welcome!</Div>
    );
}